import Vue from "vue";
import VueRouter from "vue-router";
import Store from "@/store";
import Welcome from "@/views/Welcome/index.vue";
import Landing from "@/views/Landing/index.vue";
import SuperBuyer from "@/views/SuperBuyer/index.vue";
import Login from "@/views/Login/index.vue";
import Catalog from "@/views/Catalog/index.vue";
import Subcatalog from "@/views/Subcatalog/index.vue";
import Article from "@/views/Article/index.vue";
import ViewArticle from "@/views/ViewArticle/index.vue";
import Signin from "@/views/Signin/index.vue";
import SigninCustomer from "@/views/SigninCustomer/index.vue";
import SigninVendor from "@/views/SigninVendor/index.vue";
import PasswordRecovery from "@/views/PasswordRecovery/index.vue";
import Profile from "@/views/Profile/index.vue";
import ChangePassword from "@/views/ChangePassword/index.vue";
import Warehouse from "@/views/Warehouse/index.vue";
import AddWarehouse from "@/views/AddWarehouse/index.vue";
import EditWarehouse from "@/views/EditWarehouse/index.vue";
import ShoppingCart from "@/views/ShoppingCart/index.vue";
import Order from "@/views/Order/index.vue";
import Vendor from "@/views/Vendor/index.vue";
import VendorDetail from "@/views/VendorDetail/index.vue";
import PaymentProcess from "@/views/PaymentProcess/index.vue";
import Disputes from "@/views/Disputes/index.vue";
import DisputeDetail from "@/views/DisputeDetail/index.vue";
import OrderDetail from "@/views/OrderDetail/index.vue";
// import TakeRate from "@/views/TakeRate/index.vue";
import Dashboard from "@/views/Dashboard/index.vue";

import Exchange from "@/views/Operations/Dashboard.vue";
import Invoices from "@/views/Operations/Invoices.vue";
import Forwards from "@/views/Operations/Forwards.vue";
import Balance from "@/views/Operations/Conversions.vue";

import Conversions from "@/views/History/Conversions.vue";
import Payments from "@/views/History/Payments.vue";

import Statement from "@/views/Wallets/AccountStatement.vue";
import Suppliers from "@/views/Wallets/Suppliers.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("@/views/Landing/index.vue"),
  },
  {
    path: "/home",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/superbuyer",
    name: "SuperBuyer",
    component: SuperBuyer,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/catalogs",
    name: "Catalog",
    component: Catalog,
  },
  {
    path: "/catalogs/:id/subcatalogs",
    name: "Subcatalog",
    component: Subcatalog,
  },
  {
    path: "/catalogs/:idCategory/subcatalogs/:idSubcategory/articles",
    name: "Article",
    component: Article,
  },
  {
    path: "/view/article/:id",
    name: "ViewArticle",
    component: ViewArticle,
  },
  {
    path: "/signin/customer",
    name: "SigninCustomer",
    component: SigninCustomer,
  },
  {
    path: "/signin/vendor",
    name: "SigninVendor",
    component: SigninVendor,
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/change-password/:id/",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/password-recovery",
    name: "PasswordRecovery",
    component: PasswordRecovery,
  },
  {
    path: "/profile/tab/:num",
    name: "Profile",
    component: Profile,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    component: Warehouse,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/warehouse/add",
    name: "AddWarehouse",
    component: AddWarehouse,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/warehouse/edit/:id",
    name: "EditWarehouse",
    component: EditWarehouse,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/shopping-cart",
    name: "ShoppingCart",
    component: ShoppingCart,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/order/tab/:num",
    name: "Order",
    component: Order,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/order-detail/:type/:id",
    name: "OrderDetail",
    component: OrderDetail,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/vendor",
    name: "Vendor",
    component: Vendor,
  },
  {
    path: "/vendor/detail/:id",
    name: "VendorDetail",
    component: VendorDetail,
  },
  {
    path: "/payment-process/",
    name: "PaymentProcess",
    component: PaymentProcess,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/disputes/tab/:num",
    name: "Disputes",
    component: Disputes,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  {
    path: "/dispute/:id",
    name: "DisputeDetail",
    component: DisputeDetail,
    meta: {
      requiresAuth: true,
      adminAuth: true,
    },
  },
  // {
  //   path: "/take_rate",
  //   name: "TakeRate",
  //   component: TakeRate,
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    redirect: {
      name: "Exchange",
    },
    meta: {
      requiresAuth: true,
    }, 
    children: [
      {
        path: "/exchange",
        name: "Exchange",
        component: Exchange,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/suppliers",
        name: "Suppliers",
        component: Suppliers,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/statement",
        name: "Statement",
        component: Statement,
        meta: {
          requiresAuth: true,
        },
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  // When opening a new route send the user to the default location (top left)
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth) {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Home" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    if (Store.state.sToken) {
      if (to.name === "Login") {
        next({ name: "Home" }); // Renderiza el componente "Home"
      }
      next();
    } else {
      next();
    }
  }
});

export default router;
