<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row justify="start" align="start" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">Proveedores</div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            Aqui podras administrar todos tus proveedores.
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="rounded-xl elevation-5 ma-15 mt-5">
      <v-toolbar dense dark color="#1d2b3c" elevation="2">
        <v-toolbar-title>Proveedores</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn elevation="0" text small>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn
          style="
            text-align: center;
            color: #f0be43;
            font-size: 15px;
            background-color: transparent;
            font-family: montserrat-bold;
            font-weight: 600;
            line-height: 24px;
            text-transform: capitalize;
            word-wrap: break-word;
            border-radius: 5px;
            border: 1px solid #f0be43;
            padding: 8px 16px;
          "
          elevation="0"
          @click="openDialogSupplier"
        >
          <v-icon left>mdi-account</v-icon>
          Agregar proveedor
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-data-table :headers="headersContacts" :items="items" :disable-sort="true" hide-default-footer>
          <template v-slot:item.type="{ item }">
            <v-chip
              v-if="item.type == 'Forex'"
              class="ma-2"
              color="orange"
              outlined
            >
              {{ item.type }}
            </v-chip>
            <v-chip
              v-else
              class="ma-2"
              color="blue"
              outlined
            >
              {{ item.type }}
            </v-chip>
          </template>
          <template v-slot:item.dTotalAmount="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dTotalAmount) }} MXN </h4>
          </template>
          <template v-slot:item.dRateProtection="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dRateProtection) }} MXN </h4>
          </template>
          <template v-slot:item.dProfitPercentage="{ item }">
            <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dProfitPercentage ? (item.dProfitPercentage + "%") : "" }} </h4>
          </template>
          <template v-slot:item.dCurrencyGain="{ item }">
            <h4 :style="{ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dCurrencyGain ? ('$ ' + formatMoney(item.dCurrencyGain) + ' USD') : "" }}  </h4>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="display: flex; align-items: center; justify-content: center;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDialogEditSupplier(item)" :disabled="loading" v-bind="attrs" v-on="on" icon color="black">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar proveedor</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn @click="openDialogDeleteSupplier(item)" :disabled="loading" v-bind="attrs" v-on="on" icon color="red">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar proveedor</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title style="display: flex; justify-content: center;">
          <span class="text-h5">Crear un proveedor</span>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form ref="formNew" style="width: 100%;" lazy-validation v-on:submit.prevent>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Nombre del proveedor*"
                    required
                    v-model="forms.new.sName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Correo del proveedor*"
                    required
                    v-model="forms.new.sEmail"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre del banco*"
                    required
                    v-model="forms.new.sBank_name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Clabe*"
                    required
                    v-model="forms.new.sAccount_number"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="RFC*"
                    required
                    v-model="forms.new.sTaxId"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="dialog = false"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="saveSupplier"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogEdit"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title style="display: flex; justify-content: center;">
          <span class="text-h5">Editar un proveedor</span>
        </v-card-title>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form ref="formDetail" style="width: 100%;" lazy-validation v-on:submit.prevent>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    label="Nombre del proveedor*"
                    required
                    v-model="forms.detail.sName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Correo del proveedor*"
                    required
                    v-model="forms.detail.sEmail"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Nombre del banco*"
                    required
                    v-model="forms.detail.sBankName"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Clabe*"
                    required
                    v-model="forms.detail.sAccountNumber"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="RFC*"
                    required
                    v-model="forms.detail.sTaxId"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="cancel()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="editSupplier()"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDelete"
      persistent
      max-width="fit-content"
    >
      <v-card>
        <v-card-title class="text-h5 my-4">
          ¿Estás seguro de que deseas eliminar al proveedor?
        </v-card-title>
        <v-card-text v-if="!loading" class="my-6">Una vez que eliminas al proveedor no podrás crear facturas asignadas a él.</v-card-text>
        <div v-else style="display: flex; justify-content: center;">
          <v-progress-circular
            indeterminate
            color="amber"
          ></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="cancel()"
          >
            Cerrar
          </v-btn>
          <v-btn
            color="#f0be43"
            :disabled="loading"
            @click="deleteSupplier()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SuppliersView",
  data() {
    return {
      texts: "",
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      loading: false,
      items: [],
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      forms: {
        new: {
          sName: "",
          sEmail: "",
          sBank_name: "",
          sAccount_number: "",
        },
        detail: {
          sPaymentRecipientId: "",
          sName: "",
          sEmail: "",
          sAccountNumber: "",
          sBankName: "",
          sTaxId: "",
        },
        delete: {
          sPaymentRecipientId: "",
        },
      },
      headersContacts: [
        { text: "Nombre", value: "sName", align: "center" },
        { text: "Correo", value: "sEmail", align: "center" },
        { text: "Nombre de banco", value: "sBankName", align: "center" },
        { text: "Numero de cuenta", value: "sAccountNumber", align: "center" },
        { text: "RFC", value: "sTaxId", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
    };
  },
  created() {
  },
  methods: {
    getSuppliers: function () {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.items = response.data.paymentRecipients
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    openDialogSupplier() {
      this.dialog = true;
    },
    openDialogEditSupplier(item) {
      console.log(item)
      this.dialogEdit = true;
      this.forms.detail = {
        sPaymentRecipientId: item.sPaymentRecipientId,
        sName: item.sName,
        sEmail: item.sEmail,
        sBankName: item.sBankName,
        sAccountNumber: item.sAccountNumber,
        sEntityBusiness: item.sEntityBusiness,
        sTaxId: item.sTaxId,
      }
    },
    openDialogDeleteSupplier(item) {
      this.dialogDelete = true;
      this.forms.delete = {
        sPaymentRecipientId: item.sPaymentRecipientId,
      }
    },
    saveSupplier() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;

        let payload = {
            sName: this.forms.new.sName,
            sEmail: this.forms.new.sEmail,
            sBankName: this.forms.new.sBank_name,
            sAccountNumber: this.forms.new.sAccount_number,
            sEntityBusiness: "COMPANY",
            sTaxId: this.forms.new.sTaxId,
        };

        DB.post(
            `${URI}/api/v1/${this.selectLanguage}/payment-recipient/`,
            payload,
            {
              headers: {
                Authorization: this.$store.state.sToken
                  ? `Bearer ${this.$store.state.sToken}`
                  : ""
              }
            }
          )
        .then((response) => {
          this.loading = false;
          this.getSuppliers();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    editSupplier() {
      if (this.$refs.formDetail.validate()) {
        this.loading = true;

        let payload = {
            sPaymentRecipientId: this.forms.detail.sPaymentRecipientId,
            sName: this.forms.detail.sName,
            sEmail: this.forms.detail.sEmail,
            sBankName: this.forms.detail.sBankName,
            sAccountNumber: this.forms.detail.sAccountNumber,
            sEntityBusiness: "COMPANY",
            sTaxId: this.forms.detail.sTaxId,
        };

        DB.put(
            `${URI}/api/v1/${this.selectLanguage}/payment-recipient/`,
            payload,
            {
              headers: {
                Authorization: this.$store.state.sToken
                  ? `Bearer ${this.$store.state.sToken}`
                  : ""
              }
            }
          )
        .then((response) => {
          this.loading = false;
          this.getSuppliers();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    deleteSupplier() {
      this.loading = true;

      let payload = {
          sPaymentRecipientId: this.forms.delete.sPaymentRecipientId,
      };

      DB.put(
          `${URI}/api/v1/${this.selectLanguage}/payment-recipient/delete`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : ""
            }
          }
        )
      .then((response) => {
        this.loading = false;
        this.getSuppliers();
        this.cancel();
      })
      .catch((error) => {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    cancel() {
      this.dialog = false;
      this.loading = false;
      this.dialogEdit = false;
      this.dialogDelete = false;
      this.forms.new = {
        sName: '',
        sEmail: '',
        sBankName: '',
        sAccountNumber: '',
        sEntityBusiness: '',
        sTaxId: ''
      };
      this.forms.detail = {
        sPaymentRecipientId: '',
        sName: '',
        sEmail: '',
        sBankName: '',
        sAccountNumber: '',
        sEntityBusiness: '',
        sTaxId: ''
      }
      this.forms.delete = {
        sPaymentRecipientId: ''
      }
      
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getSuppliers();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
