<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row justify="start" align="start" class="ma-0">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">Historial de conversiones</div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            Aqui encontraras el historial de todas tus conversiones realizadas.
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="rounded-xl elevation-5 ma-15 mt-5">
      <v-toolbar dense dark color="#1d2b3c" elevation="2">
        <v-toolbar-title>Conversiones</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn elevation="0" text small>
        </v-btn>
      </v-toolbar>
      <v-card-title>
      </v-card-title>
      <v-card-text>
        <v-spacer></v-spacer>
        <v-data-table :headers="headersForex" :items="items" :disable-sort="true">
          <template v-slot:item.sTransactionType="{ item }">
            <v-chip
              v-if="item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
              class="ma-2"
              color="red"
              outlined
            >
              {{ 'Forward' }}
            </v-chip>
            <v-chip
              v-else-if="item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'"
              class="ma-2"
              color="blue"
              outlined
            >
              {{ 'Forex' }}
            </v-chip>
          </template>
          <template v-slot:item.dAmount="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dAmount) }} USD </h4>
          </template>
          
          <template v-slot:item.created_at="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ formatDate(item.created_at) }}  </h4>
          </template>
          
          <template v-slot:item.dDesAmount="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dDesAmount) }} MXN </h4>
          </template>
          <template v-slot:item.dExchangeRate="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> $ {{ formatMoney(item.dExchangeRate) }} MXN </h4>
          </template>
          <template v-slot:item.invoice="{ item }">
            <div v-if="item.invoice.length > 0">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;" v-for="(item, idx) in item.invoice"> {{ item.invoice.sInvoice }}</h4>
            </div>
            <div v-else>
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;">{{ "N/A" }}</h4>
            </div>
          </template>
          <template v-slot:item.purchaseDate="{ item }">
            <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoice.length > 0 ? formatDate(item.invoice[0].invoice.dPurchaseDate) : "N/A" }}  </h4>
          </template>
          <template v-slot:item.percentProfit="{ item }">
            <h4 v-if="item.percentProfit" :style="{ color: item.percentProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.percentProfit ? (formatMoney(item.percentProfit) + "%") : "N/A" }} </h4>
            <h4 v-else> {{ "N/A" }}</h4>
          </template>
          <template v-slot:item.dollarProfit="{ item }">
            <h4 v-if="item.dollarProfit" :style="{ color: item.dollarProfit < 0 ? 'red' : 'green'}" style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.dollarProfit ? ('$ ' + formatMoney(item.dollarProfit) + ' USD') : "" }}</h4>
            <h4 v-else> {{ "N/A" }}</h4>
          </template>
          <template v-slot:item.actions="{ item }">
            <div style="display: flex; align-items: center; justify-content: center;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn disabled v-bind="attrs" v-on="on" icon color="black">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      selected: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      tab: 0,
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      headersForex: [
        { text: "Fecha de creación", value: "created_at", align: "center" },
        { text: "Type Transaction", value: "sTransactionType", align: "center" },
        { text: "Total amount USD", value: "dAmount", align: "center" },
        { text: "Total amount MXN", value: "dDesAmount", align: "center" },
        { text: "Exchange rate", value: "dExchangeRate", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "percentProfit", align: "center" },
        { text: "Currency gain", value: "dollarProfit", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      miniVariant: true,
      expandOnHover: false,
      transactions: [],
      invoices: [],
    };
  },
  methods: {
    getPaymentsHistory() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/history`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      }).then((response) => {
        this.items = response.data.transactions
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(formattedNumber);
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getPaymentsHistory();
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
