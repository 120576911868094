var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-container',{staticClass:"px-15",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 mb-5"},[_c('div',{staticClass:"ma-4"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline-medium text-primary"},[_vm._v("Forwards Activos")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-large text-secondary"},[_vm._v(" Aqui encontraras tus forwards activos de que estan pendientes de realizarse. ")])])],1),_c('v-spacer'),_c('v-card',{staticClass:"card-main mx-3 my-3",staticStyle:{"border":"1px solid","height":"fit-content"},attrs:{"width":"350px","elevation":"4","outlined":""}},[_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"pl-5",attrs:{"cols":"9"}},[_c('div',{staticClass:"usd-balance"},[_vm._v("USD Balance")]),_c('div',{staticClass:"content-balance"},[_vm._v(" $"+_vm._s(_vm.formatMoney(_vm.balanceUsd ? _vm.balanceUsd : 0))+" USD ")])]),_c('v-col',{staticClass:"text-right pr-5",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"src":require("../../assets/images/Flags_usa.png"),"alt":"usa-flag","width":"50","height":"30"}})],1)],1),_c('div',{staticClass:"pl-3 pt-2 blocked-amount"},[_vm._v(" Monto bloqueado forwards: "),_c('span',{staticClass:"blocked-amount-2"},[_vm._v("$0.00")])])],1)],1)],1),_c('v-card',{staticClass:"rounded-xl elevation-5"},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"#1d2b3c","elevation":"2"}},[_c('v-toolbar-title',[_vm._v("Forwards Activos")]),_c('v-spacer')],1),_c('v-card-title'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersForwards,"items":_vm.conversions,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.diasFaltantes > 0)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue","outlined":""}},[_vm._v(" "+_vm._s("Forward")+" ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s("Forward")+" ")])]}},{key:"item.diasFaltantes",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.diasFaltantes < 0 ? 'red' : 'black'})},[_vm._v(" "+_vm._s(item.diasFaltantes + " days")+" ")])]}},{key:"item.dDesAmount",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dDesAmount))+" MXN ")])]}},{key:"item.dExchangeRate",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dExchangeRate))+" MXN ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.dForwardTransactionDate",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.dForwardTransactionDate))+" ")])]}},{key:"item.dProfitPercentage",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.dProfitPercentage ? (item.dProfitPercentage + "%") : "")+" ")])]}},{key:"item.dCurrencyGain",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dCurrencyProfitPercentage < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.dCurrencyGain ? ('$ ' + _vm.formatMoney(item.dCurrencyGain) + ' USD') : "")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit item")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cash-sync")])],1)]}}],null,true)},[_c('span',[_vm._v("Solicitar cobro")])])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }