var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-4"},[_c('v-container',{staticClass:"px-15",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0",attrs:{"justify":"start","align":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"headline-medium text-primary"},[_vm._v("Historial de conversiones")])]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"body-large text-secondary"},[_vm._v(" Aqui encontraras el historial de todas tus conversiones realizadas. ")])])],1)],1),_c('v-card',{staticClass:"rounded-xl elevation-5 ma-15 mt-5"},[_c('v-toolbar',{attrs:{"dense":"","dark":"","color":"#1d2b3c","elevation":"2"}},[_c('v-toolbar-title',[_vm._v("Conversiones")]),_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0","text":"","small":""}})],1),_c('v-card-title'),_c('v-card-text',[_c('v-spacer'),_c('v-data-table',{attrs:{"headers":_vm.headersForex,"items":_vm.items,"disable-sort":true},scopedSlots:_vm._u([{key:"item.sTransactionType",fn:function(ref){
var item = ref.item;
return [(item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","outlined":""}},[_vm._v(" "+_vm._s('Forward')+" ")]):(item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a')?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"blue","outlined":""}},[_vm._v(" "+_vm._s('Forex')+" ")]):_vm._e()]}},{key:"item.dAmount",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dAmount))+" USD ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])]}},{key:"item.dDesAmount",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dDesAmount))+" MXN ")])]}},{key:"item.dExchangeRate",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" $ "+_vm._s(_vm.formatMoney(item.dExchangeRate))+" MXN ")])]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [(item.invoice.length > 0)?_c('div',_vm._l((item.invoice),function(item,idx){return _c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.invoice.sInvoice))])}),0):_c('div',[_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(_vm._s("N/A"))])])]}},{key:"item.purchaseDate",fn:function(ref){
var item = ref.item;
return [_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"}},[_vm._v(" "+_vm._s(item.invoice.length > 0 ? _vm.formatDate(item.invoice[0].invoice.dPurchaseDate) : "N/A")+" ")])]}},{key:"item.percentProfit",fn:function(ref){
var item = ref.item;
return [(item.percentProfit)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.percentProfit < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.percentProfit ? (_vm.formatMoney(item.percentProfit) + "%") : "N/A")+" ")]):_c('h4',[_vm._v(" "+_vm._s("N/A"))])]}},{key:"item.dollarProfit",fn:function(ref){
var item = ref.item;
return [(item.dollarProfit)?_c('h4',{staticStyle:{"text-wrap":"nowrap","font-family":"'Montserrat', sans-serif"},style:({ color: item.dollarProfit < 0 ? 'red' : 'green'})},[_vm._v(" "+_vm._s(item.dollarProfit ? ('$ ' + _vm.formatMoney(item.dollarProfit) + ' USD') : ""))]):_c('h4',[_vm._v(" "+_vm._s("N/A"))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":"","icon":"","color":"black"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }