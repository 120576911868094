<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer
        v-model="drawer"
        width="280"
        app
        absolute
      >
        <v-list-item title="My Application" subtitle="Vuetify">
          <v-img
            :src="require('../../assets/logos/logo-bg-white.svg')"
          ></v-img>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="(section, index) in sections">
          <v-list-group
            :prepend-icon="section.icon"
            :value="false"
            v-model="section.active"
            :class="{ 'active-item': selectedMenuItem === section.value }"
            :color="'#f0be44'"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ section.title }}</v-list-item-title>
            </template>
            <v-list-item
              v-for="(item, itemIndex) in section.items"
              :key="itemIndex"
              link
              :to="item.path"
              color="#f0be44"
              :class="{ 'active-item': selectedMenuItem === item.value }"
              :disabled="item.disabled"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-navigation-drawer>
      <!-- <v-app-bar dense height="83" app style="display: flex; justify-content: space-between; border-bottom: 1px solid #c7c7c7; align-items: center; padding: 1.25rem;" flat color="#ffffff">
      </v-app-bar> -->
      <v-app-bar
        color="#ffffff"
        absolute
        app
        height="83"
      >
        <v-toolbar-title style="font-size: .875rem; line-height: 1.25rem; font-weight: 600; display: flex; align-items: center;" class="pa-0">{{ formattedDate }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-3" style="display: flex; cursor: pointer; border: 1px #585858 solid; align-items: center; border-radius: 15px; padding: 10px" v-bind="attrs" v-on="on">
                <img
                  v-if="selectLanguage == 'sp'"
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_mex.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <img
                  v-else
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_usa.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <h5>{{ selectLanguage == "sp" ? "Español" : "English" }}</h5>
                
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <language-component style="background-color: black; padding: 15px;"></language-component>
          </v-menu>
        </div>
      </v-app-bar>
      <v-main>
          <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      currentDate: moment(),
      drawer: null,
      balance: "0",
      items: [],
      tab: 0,
      editableData: {},
      selectedMenuItem: "",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY"
      },
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      timerCount: 30,
      userName: "",
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              path: "/exchange",
              disabled: false
            }
          ]
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              path: "/suppliers",
              disabled: false
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              path: "/statement",
              disabled: true
            }
          ]
        }
      ],
      
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`
        }
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/sub-account/`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    updateTime() {
      this.currentDate = moment(); // Actualiza la fecha y hora
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.interval = setInterval(this.updateTime, 1000); // 1000 ms = 1 segundo
  },
  beforeDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    clearInterval(this.interval);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedDate() {
      return this.currentDate.format('MMMM DD, YYYY, HH:mm:ss [GMT]Z');
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    }
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    }
  }
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
.active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
}

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #f0be43;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}

</style>
